#layout-wrapper {
  #page-topbar {
    z-index: 1020;
  }

  .topnav {
    z-index: 1015;
  }
}

div.main-content {
  .sticky-content-top {
    position: sticky;
    z-index: 1010;
    top: $header-height;
    background-color: $body-bg;
  }

  @include media-breakpoint-up(lg) {
    .sticky-content-top {
      top: $header-height + 52.8;
    }
  }
}

// tables
.table-responsive {
  min-height: 175px;
}
