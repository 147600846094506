.main-content:after {
  content: '';
  display: block;
}

.site-footer,
.main-content:after {
  min-height: 70px;
}

.site-footer {
  padding: 5px;
  background: rgb(255, 255, 255);
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1030;
  box-shadow: 5px 5px 5px 5px #acacac;
}
